import './footer.scss';

function Footer() {
    return(
        <footer>
            <p>Edoardo Palamito - Frontend Developer</p>
        </footer>
    )
}

export default Footer;